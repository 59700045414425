import { PublicKey } from "@solana/web3.js";

export const validateSolAddress = (address: string) => {
  try {
    let pubkey = new PublicKey(address);
    let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
    return isSolana;
  } catch (error) {
    return false;
  }
};

export const calculateInputWidth = (str: string) => {
  // Check if the string contains a dot
  const containsDot = str.includes(".");

  // Count the number of '1's in the string
  const countOfOnes = str.split("").filter((char) => char === "1").length;
  const totalLengthWithDot = containsDot ? str.length - 1 : str.length;

  const inputWidth =
    totalLengthWithDot - countOfOnes + countOfOnes * 0.75 + (containsDot ? 0.5 : 0);

  return Number(inputWidth.toFixed(2)) + 0.75;
};

export function validateDecimalPlaces(numStr: string) {
  // Regular expression to check if the number has more than 9 decimal places
  const regex = /^\d+(\.\d{0,9})?$/;

  return regex.test(numStr);
}

export const doesWebsiteMatch = (website: string) => website === window.origin;
